/* General Styles */
*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    --ff-primary: 'Source Sans Pro', sans-serif;
    --ff-secondary: 'Source Code Pro', monospace;
    --ff-ternary: 'Jost', sans-serif;

    --fw-reg: 300;
    --fw-bold: 900;

    --clr-light: #fff;
    --clr-dark: #303030;
    --clr-accent: #30124d;
    --clr-bright: #592b85;
    --clr-superbright: #d7b0fc;

    --fs-h1: 3rem;
    --fs-h2: 2.25rem;
    --fs-h3: 1.25rem;
    --fs-body: 1rem;
    --fs-icons: 2rem;
    --fs-navbar: 1.1rem;

    --shadow: .25em .25em .75em rgba(0, 0, 0, 0.5);
    --lightshadow: .25em .25em .75em rgba(0, 0, 0, 0.2);
}

/* Increase font sizes when the window is large */
@media (min-width: 850px) {
  :root {
      --fs-h1: 3.25rem;
      --fs-h2: 2.5rem;
      --fs-h3: 1.5rem;
      --fs-body: 1.125rem;
      --fs-icons: 2.2rem;
      --fs-navbar: 1.2rem;
  }
}

/* Increase font sizes when the window is large */
@media (min-width: 1000px) {
    :root {
        --fs-h1: 4.5rem;
        --fs-h2: 3.75rem;
        --fs-h3: 1.5rem;
        --fs-body: 1.125rem;
        --fs-icons: 2.25rem;
        --fs-navbar: 1.25rem;
    }
}

body, html {
    background: var(--clr-light);
    color: var(--clr-dark);
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: var(--ff-primary);
    font-size: var(--fs-body);
    line-height: 1.6;
}

img {
    display: block;
    max-width: 100%;
}

section {
    padding: 3em 2em;
}

/* Setting up font sizes and colors */
h1, h2, h3 {
    line-height: 1;
    margin: 0;
}

strong {
    font-weight: var(--fw-bold);
}

h1 {
    font-size: var(--fs-h1);
}

h2 {
    font-size: var(--fs-h2);
}

h3 {
    font-size: var(--fs-h3);
}
