/* Beyond section -- cards to go to project/resume pages */
.beyond {
    display: flex;
    justify-content: center;
    margin: 75px 0;
}

.beyond a {
    text-decoration: none;
    color: var(--clr-dark);
}

.card {
    width: 40%;
    height: auto;
    outline-color: var(--clr-dark);
    outline-width: 2px;
    outline-style: solid;
    border-radius: 2px;
    margin: 0 4em;
    text-align: center;
    padding-top: 1em;
    transition: all 0.25s ease;
    box-shadow: var(--lightshadow);
    padding: 1em 1em;
}

.card-label::after {
    content: '';
    display: block;
    width: 25%;
    height: 2px;
    margin: 0.5em auto;
    background: var(--clr-dark);
    opacity: 0.5;
}

.card:hover {
    transform: scale(1.03);
    transform-origin: center;
}

@media (max-width: 900px) {
    .beyond {
        display: inline;
        align-content: center;
    }

    .card {
        margin: 3em auto;
        width: 80%;
    }
}

/* when links are hovered over, change color */
.socials li a i:hover {
    color: var(--clr-superbright);
}
