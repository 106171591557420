/* About section */
.about {
    background-color: #242424;
    background-image: url("../assets/mfa.jpg");
    background-size: cover;
    background-blend-mode: multiply;
    background-position: center;
    text-align: left;
}

.abt-title {
    text-align: center;
}

.abt-sections,
.abt-title {
    color: var(--clr-light);
}

.abt-title::after {
    content: '';
    display: block;
    width: 25%;
    height: 1px;
    margin: 0.5em auto 1em;
    background: var(--clr-light);
    opacity: 0.5;
}

@media (min-width: 800px) {
    .abt-sections {
        max-width: 1100px;
        margin: 0 auto;
    }

    .abt-section + .abt-section {
        margin-bottom: 2em;
    }
}
